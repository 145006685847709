import React, { useState } from 'react'

import {
  Box,
  Button,
  GridList,
  Heading,
  LoadingOverlay,
  Paragraph,
  Spacer
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { useLorem } from '../../lorem'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => {
  const heading = useLorem({ words: 3 })
  const paragraph = useLorem({ sentences: 1 })
  const [enabled, setEnabled] = useState(false)

  return (
    <Content
      heading="LoadingOverlay"
      components={[{ component: LoadingOverlay }]}
      status={[{ type: 'accessible', version: '15.1.0' }]}
    >
      <Section>
        <Playground>
          <>
            <Spacer marginBottom="xl">
              <LoadingOverlay enabled={enabled}>
                <GridList
                  sm={3}
                  noMargin
                >
                  {[...Array(6).keys()].map(i => (
                    <Box
                      noMargin
                      key={i}
                    >
                      <Heading
                        level={2}
                        size={4}
                      >
                        {heading}
                      </Heading>
                      <Paragraph noMargin>{paragraph}</Paragraph>
                    </Box>
                  ))}
                </GridList>
              </LoadingOverlay>
            </Spacer>
            <Button
              onClick={() => {
                if (!enabled) {
                  setEnabled(true)

                  setTimeout(() => {
                    setEnabled(false)
                  }, 2000)
                }
              }}
            >
              Lataa
            </Button>
          </>
        </Playground>
      </Section>
      <Section title="Saavutettavuus">
        <Paragraph>
          Komponentti luetaan oletuksena ruudunlukijalla &quot;Ladataan...&quot;
        </Paragraph>
        <Paragraph>
          Älä käytä useampaa <Code>LoadingOverlay</Code>ta samalla sivulla.
        </Paragraph>
      </Section>
    </Content>
  )
}

export default Page
